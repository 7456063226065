import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/white_publish_24px.png'
import _imports_3 from '@/assets/icons/white_check_2_32px.png'
import _imports_4 from '@/assets/image/logo_myculture.png'
import _imports_5 from '@/assets/icons/navigate_left_black_24px.png'
import _imports_6 from '@/assets/icons/white_navigate_back_24px.png'


const _hoisted_1 = {
  key: 0,
  class: "body-width modal"
}
const _hoisted_2 = { class: "box-card" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_8 = {
  key: 2,
  class: "btn btn-create btn-progress btn-primary disable-true"
}
const _hoisted_9 = {
  key: 1,
  class: "menu-container flex-center"
}
const _hoisted_10 = { class: "body-width-reponsive-max" }
const _hoisted_11 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_12 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_13 = { class: "label-container" }
const _hoisted_14 = { class: "nav-text-header" }
const _hoisted_15 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_16 = { class: "fs-12 fw-700" }
const _hoisted_17 = { class: "fs-12 fw-700" }
const _hoisted_18 = {
  key: 0,
  src: _imports_0,
  class: "icon-20 ml-12",
  alt: "navigate_right_black_24px"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_20 = {
  key: 1,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_21 = { class: "fs-12 fw-700" }
const _hoisted_22 = { class: "ml-24 flex-mid" }
const _hoisted_23 = {
  key: 3,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_24 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_25 = {
  key: 2,
  class: "body-width",
  style: {"position":"relative"},
  id: "assessment_setup_values_and_traits"
}
const _hoisted_26 = { style: {"margin-top":"6.4rem"} }
const _hoisted_27 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_28 = { class: "step-menu-container vertical" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "menu-inner" }
const _hoisted_31 = { class: "step-num" }
const _hoisted_32 = { class: "step-name" }
const _hoisted_33 = {
  key: 0,
  class: "step-desc"
}
const _hoisted_34 = {
  key: 0,
  class: "content-header"
}
const _hoisted_35 = {
  key: 1,
  class: "content-inner"
}
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = {
  key: 6,
  style: {"display":"flex","gap":"3.8rem"}
}
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "item-content" }
const _hoisted_45 = { class: "item-name" }
const _hoisted_46 = { class: "item-traits" }
const _hoisted_47 = {
  key: 2,
  class: "content-inner"
}
const _hoisted_48 = { class: "item-content" }
const _hoisted_49 = { class: "item-name" }
const _hoisted_50 = { class: "item-traits" }
const _hoisted_51 = { class: "item-content" }
const _hoisted_52 = { class: "item-name" }
const _hoisted_53 = { class: "item-traits" }
const _hoisted_54 = {
  key: 3,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_55 = {
  key: 0,
  class: "select-rating-container"
}
const _hoisted_56 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_57 = ["innerHTML"]
const _hoisted_58 = { class: "flex-center fs-16 fw-700 mt-40" }
const _hoisted_59 = { class: "mr-auto" }
const _hoisted_60 = { class: "ml-auto" }
const _hoisted_61 = {
  class: "value-label",
  style: {"margin-top":"1.6rem"}
}
const _hoisted_62 = { class: "container-rating" }
const _hoisted_63 = ["onClick"]
const _hoisted_64 = {
  key: 4,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_65 = { class: "select-rating-container" }
const _hoisted_66 = { class: "fs-24 fw-900" }
const _hoisted_67 = { class: "fs-16 fc-1B1C1E mt-12" }
const _hoisted_68 = { class: "set-up-value-traits mt-38" }
const _hoisted_69 = {
  key: 5,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_70 = { class: "select-rating-container" }
const _hoisted_71 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_72 = ["innerHTML"]
const _hoisted_73 = { class: "flex-center fs-16 fw-700 mt-40" }
const _hoisted_74 = { class: "mr-auto" }
const _hoisted_75 = { class: "ml-auto" }
const _hoisted_76 = { class: "container-rating" }
const _hoisted_77 = ["onClick"]
const _hoisted_78 = {
  key: 6,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_79 = { class: "select-rating-container" }
const _hoisted_80 = { class: "fs-24 fw-900" }
const _hoisted_81 = { class: "fs-16 fc-1B1C1E mt-12" }
const _hoisted_82 = { class: "set-up-value-traits mt-38" }
const _hoisted_83 = {
  key: 7,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_84 = { class: "max-width-520 mx-auto center" }
const _hoisted_85 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_86 = { class: "mt-12 fs-16" }
const _hoisted_87 = { class: "fw-700" }
const _hoisted_88 = { class: "mt-40 fs-16" }
const _hoisted_89 = {
  key: 3,
  class: "body-width modal"
}
const _hoisted_90 = { class: "box-card" }
const _hoisted_91 = { class: "modal-title" }
const _hoisted_92 = { class: "modal-body" }
const _hoisted_93 = { class: "modal-footer" }
const _hoisted_94 = { class: "remove-header remove-padding-modal" }
const _hoisted_95 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_96 = { class: "fs-18 fw-700" }
const _hoisted_97 = { class: "fs-15 mt-20" }
const _hoisted_98 = {
  key: 0,
  class: "fs-15 fw-700 mt-20"
}
const _hoisted_99 = ["placeholder"]
const _hoisted_100 = {
  key: 2,
  class: "fs-15 fw-700 mt-16"
}
const _hoisted_101 = ["placeholder"]
const _hoisted_102 = { class: "flex-center mt-32" }
const _hoisted_103 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_104 = { class: "fs-18 fw-700" }
const _hoisted_105 = { class: "fs-15 mt-20" }
const _hoisted_106 = {
  key: 0,
  class: "fs-15 fw-700 mt-20"
}
const _hoisted_107 = ["placeholder"]
const _hoisted_108 = {
  key: 2,
  class: "fs-15 fw-700 mt-16"
}
const _hoisted_109 = ["placeholder"]
const _hoisted_110 = { class: "flex-center mt-32" }
const _hoisted_111 = { class: "title" }
const _hoisted_112 = { class: "pros" }
const _hoisted_113 = { class: "cons" }
const _hoisted_114 = { class: "close" }
const _hoisted_115 = {
  key: 4,
  class: "menu-container flex-center fixed"
}
const _hoisted_116 = { class: "body-width flex-center" }
const _hoisted_117 = {
  class: "mr-auto",
  style: {"width":"18rem"}
}
const _hoisted_118 = ["src"]
const _hoisted_119 = {
  key: 1,
  src: _imports_4,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_120 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_121 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_122 = {
  key: 1,
  class: "ml-auto flex-center"
}
const _hoisted_123 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_124 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_125 = {
  key: 2,
  class: "ml-auto flex-center"
}
const _hoisted_126 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_127 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_128 = {
  key: 3,
  class: "ml-auto flex-center"
}
const _hoisted_129 = {
  key: 0,
  src: _imports_5,
  alt: "navigate_left_black_24px",
  class: "icon-24 mr-16"
}
const _hoisted_130 = {
  key: 1,
  src: _imports_6,
  alt: "white_navigate_back_24px",
  class: "icon-24 mr-16"
}
const _hoisted_131 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_132 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_133 = {
  key: 5,
  class: "mock-menu-container"
}
const _hoisted_134 = {
  key: 6,
  class: "body-width",
  style: {"padding-top":"6rem"}
}
const _hoisted_135 = {
  key: 0,
  class: "box-card mb-32 center bg-FFFFFF",
  style: {"min-height":"420px","padding":"8rem 3.2rem 3.2rem"}
}
const _hoisted_136 = { class: "max-width-520 mx-auto" }
const _hoisted_137 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_138 = { class: "mt-12 fs-16" }
const _hoisted_139 = { class: "mt-32 fs-16" }
const _hoisted_140 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_141 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_142 = {
  key: 1,
  class: "btn btn-create btn-progress btn-50 btn-primary mx-auto",
  style: {"width":"16.6rem","margin-top":"6.4rem"}
}
const _hoisted_143 = {
  key: 1,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_144 = { class: "select-rating-container" }
const _hoisted_145 = { class: "fs-24 fw-900" }
const _hoisted_146 = { class: "fs-16 fc-1B1C1E mt-12" }
const _hoisted_147 = { class: "set-up-value-traits mt-38" }
const _hoisted_148 = {
  key: 2,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_149 = {
  key: 0,
  class: "select-rating-container"
}
const _hoisted_150 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_151 = ["innerHTML"]
const _hoisted_152 = { class: "flex-center fs-16 fw-700 mt-40" }
const _hoisted_153 = { class: "mr-auto" }
const _hoisted_154 = { class: "ml-auto" }
const _hoisted_155 = {
  class: "value-label",
  style: {"margin-top":"1.6rem"}
}
const _hoisted_156 = { class: "container-rating" }
const _hoisted_157 = ["onClick"]
const _hoisted_158 = {
  key: 3,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_159 = { class: "select-rating-container" }
const _hoisted_160 = { class: "fs-24 fw-900" }
const _hoisted_161 = { class: "fs-16 fc-1B1C1E mt-12" }
const _hoisted_162 = { class: "set-up-value-traits mt-38" }
const _hoisted_163 = {
  key: 4,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_164 = { class: "select-rating-container" }
const _hoisted_165 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_166 = ["innerHTML"]
const _hoisted_167 = { class: "flex-center fs-16 fw-700 mt-40" }
const _hoisted_168 = { class: "mr-auto" }
const _hoisted_169 = { class: "ml-auto" }
const _hoisted_170 = { class: "container-rating" }
const _hoisted_171 = ["onClick"]
const _hoisted_172 = {
  key: 5,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_173 = { class: "max-width-520 mx-auto center" }
const _hoisted_174 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_175 = { class: "mt-12 fs-16" }
const _hoisted_176 = { class: "fw-700" }
const _hoisted_177 = { class: "mt-40 fs-16" }
const _hoisted_178 = {
  key: 7,
  class: "remove-header remove-padding-modal"
}
const _hoisted_179 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_180 = { class: "fs-18 fw-700" }
const _hoisted_181 = { class: "fs-15 mt-20" }
const _hoisted_182 = {
  key: 0,
  class: "fs-15 fw-700 mt-20"
}
const _hoisted_183 = ["placeholder"]
const _hoisted_184 = {
  key: 2,
  class: "fs-15 fw-700 mt-16"
}
const _hoisted_185 = ["placeholder"]
const _hoisted_186 = { class: "flex-center mt-32" }
const _hoisted_187 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_188 = { class: "fs-18 fw-700" }
const _hoisted_189 = { class: "fs-15 mt-20" }
const _hoisted_190 = {
  key: 0,
  class: "fs-15 fw-700 mt-20"
}
const _hoisted_191 = ["placeholder"]
const _hoisted_192 = {
  key: 2,
  class: "fs-15 fw-700 mt-16"
}
const _hoisted_193 = ["placeholder"]
const _hoisted_194 = { class: "flex-center mt-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_transfer = _resolveComponent("el-transfer")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step == 'get_start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("setup.valueAndTraits.Setup_your_Values")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.valueAndTraits.Your_company_s_core_values_are")) + " ", 1),
              _cache[58] || (_cache[58] = _createElementVNode("br", null, null, -1)),
              _cache[59] || (_cache[59] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("setup.valueAndTraits.Remember_that_every_value")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                false
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      disabled: !_ctx.existsTemplates.length,
                      modelValue: _ctx.selectedExistsTemplate,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                      class: "ep-select-44",
                      placeholder: _ctx.$t('setup._Use_the_same_setup')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item._id,
                            label: item.form_name,
                            value: item._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["disabled", "modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startSetupValues && _ctx.startSetupValues(...args))),
                      class: "btn btn-primary"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("img", _hoisted_7))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[60] || (_cache[60] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve",
                        class: "icon-24"
                      }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ])),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-default"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[61] || (_cache[61] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
                  class: "btn-left"
                }, _cache[62] || (_cache[62] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "icons_navigate_right_black_24px",
                    class: "icon-16 animetion",
                    style: {"transform":"rotate(180deg)"}
                  }, null, -1)
                ])),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("setup.valueAndTraits.Setup_your_Values")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isFirstStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                    }, [
                      _cache[63] || (_cache[63] = _createElementVNode("img", {
                        src: _imports_0,
                        class: "icon-20 mr-12",
                        alt: "navigate_right_black_24px",
                        style: {"transform":"rotate(180deg)"}
                      }, null, -1)),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("create.Previous")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (!_ctx.isLastStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", `${
              _ctx.canNextStep && _ctx.publishSubmitStatus
                ? 'btn-primary'
                : 'disable-true'
            }`])
                    }, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("create.Next")), 1),
                      (_ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                              : (_openBlock(), _createElementBlock("img", _hoisted_19))
                          ], 64))
                        : (_openBlock(), _createElementBlock("svg", _hoisted_20, _cache[64] || (_cache[64] = [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ], -1)
                          ])))
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.isLastStep && _ctx.publishSubmitStatus)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                      class: "ml-16 btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("create.Publish")), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          class: "icon-20",
                          style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                          alt: "icons_white_publish_24px"
                        }, null, 4)
                      ])
                    ]))
                  : (_ctx.isLastStep)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("create.Publish")), 1),
                        _cache[65] || (_cache[65] = _createElementVNode("svg", {
                          width: "3rem",
                          height: "3rem",
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve"
                        }, [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ])
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `menu_${i}`,
                    class: _normalizeClass(`menu ${_ctx.tabNextStepCls(item.step)}`),
                    style: _normalizeStyle(`display: ${item.visible === false ? 'none' : ''}`),
                    onClick: ($event: any) => (_ctx.gotoStep(item.step))
                  }, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, _toDisplayString(item.name), 1),
                      _createElementVNode("div", _hoisted_32, _toDisplayString(item.title), 1),
                      (item.desc)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(item.desc), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 14, _hoisted_29))
                }), 128))
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["content-container", `${_ctx.step}`])
              }, [
                (_ctx.canToggleStep)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(_ctx.getSelectedTotal()) + " " + _toDisplayString(_ctx.$t("create.Selected")), 1))
                  : _createCommentVNode("", true),
                (_ctx.canToggleStep)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      (_ctx.step == 'select_core_values')
                        ? (_openBlock(), _createElementBlock("h3", {
                            key: 0,
                            innerHTML: 
                _ctx.$t('setup.valueAndTraits.What_are_your_core_values_that_set')
              
                          }, null, 8, _hoisted_36))
                        : _createCommentVNode("", true),
                      (_ctx.step == 'select_core_values')
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            innerHTML: 
                _ctx.$t('setup.valueAndTraits.These_are_the_unique_values_that')
              
                          }, null, 8, _hoisted_37))
                        : _createCommentVNode("", true),
                      (_ctx.step == 'select_permision_to_play_values')
                        ? (_openBlock(), _createElementBlock("h3", {
                            key: 2,
                            innerHTML: 
                _ctx.$t('setup.valueAndTraits.What_are_your_permission_to_play')
              
                          }, null, 8, _hoisted_38))
                        : _createCommentVNode("", true),
                      (_ctx.step == 'select_permision_to_play_values')
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 3,
                            innerHTML: _ctx.$t('setup.valueAndTraits.These_are_basic_ethical_values')
                          }, null, 8, _hoisted_39))
                        : _createCommentVNode("", true),
                      (_ctx.step == 'select_non_essential_values')
                        ? (_openBlock(), _createElementBlock("h3", {
                            key: 4,
                            innerHTML: 
                _ctx.$t(
                  'setup.valueAndTraits.What_are_non_essential_values_that_do_not'
                )
              
                          }, null, 8, _hoisted_40))
                        : _createCommentVNode("", true),
                      (_ctx.step == 'select_non_essential_values')
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 5,
                            innerHTML: 
                _ctx.$t(
                  'setup.valueAndTraits.These_are_values_that_do_not_significantly'
                )
              
                          }, null, 8, _hoisted_41))
                        : _createCommentVNode("", true),
                      (
                _ctx.valuesAndTraits &&
                _ctx.valuesAndTraits.scenarios &&
                _ctx.valuesAndTraits.scenarios.definingYourValues &&
                _ctx.valuesAndTraits.scenarios.definingYourValues.questions
              )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(
                  Array(
                    Math.ceil(
                      _ctx.valuesAndTraits.scenarios.definingYourValues.questions
                        .length / 6
                    )
                  ).keys()
                ), (i) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: 'items_' + i,
                                class: _normalizeClass(`items items-${i}`)
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues.questions.slice(
                    i * 6,
                    i * 6 + 6
                  ), (data) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: data.id,
                                    onClick: ($event: any) => (_ctx.toggleCheckbox(data)),
                                    class: _normalizeClass(["item", `${_ctx.selectedCls(data)} ${_ctx.disabledCls(data)}`])
                                  }, [
                                    _cache[66] || (_cache[66] = _createElementVNode("div", { class: "checkbox flex-mid" }, [
                                      _createElementVNode("div", { class: "checked flex-mid" }, [
                                        _createElementVNode("img", {
                                          src: _imports_3,
                                          alt: "icons_white_check_2_32px",
                                          class: "icon-12"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_44, [
                                      _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.lang(data, "title")), 1),
                                      _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.getItemStatus(data)), 1)
                                    ])
                                  ], 10, _hoisted_43))
                                }), 128))
                              ], 2))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'review')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Review_your_values_selection")), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Confirm_the_assignment_of_each_value")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ($items, path) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: 'items_' + path
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["title", path])
                          }, _toDisplayString(_ctx.$t("setup.valueAndTraits." + path)), 3),
                          false
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(`items horizontal items-${path}`)
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSelectedItems(_ctx.$step), (data) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: 'item_' + data.id,
                                    class: "item"
                                  }, [
                                    _cache[67] || (_cache[67] = _createStaticVNode("<div class=\"checkbox flex-mid\" data-v-0b65b62f><div class=\"checked flex-mid\" data-v-0b65b62f><svg width=\"7\" height=\"11\" viewBox=\"0 0 7 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-0b65b62f><path d=\"M4 9.25C4 8.55964 4.55964 8 5.25 8C5.94036 8 6.5 8.55964 6.5 9.25C6.5 9.94036 5.94036 10.5 5.25 10.5C4.55964 10.5 4 9.94036 4 9.25Z\" fill=\"#696C80\" data-v-0b65b62f></path><path d=\"M0 9.25C0 8.55964 0.559644 8 1.25 8C1.94036 8 2.5 8.55964 2.5 9.25C2.5 9.94036 1.94036 10.5 1.25 10.5C0.559644 10.5 0 9.94036 0 9.25Z\" fill=\"#696C80\" data-v-0b65b62f></path><path d=\"M4 5.25C4 4.55964 4.55964 4 5.25 4C5.94036 4 6.5 4.55964 6.5 5.25C6.5 5.94036 5.94036 6.5 5.25 6.5C4.55964 6.5 4 5.94036 4 5.25Z\" fill=\"#696C80\" data-v-0b65b62f></path><path d=\"M0 5.25C0 4.55964 0.559644 4 1.25 4C1.94036 4 2.5 4.55964 2.5 5.25C2.5 5.94036 1.94036 6.5 1.25 6.5C0.559644 6.5 0 5.94036 0 5.25Z\" fill=\"#696C80\" data-v-0b65b62f></path><path d=\"M4 1.25C4 0.559644 4.55964 0 5.25 0C5.94036 0 6.5 0.559644 6.5 1.25C6.5 1.94036 5.94036 2.5 5.25 2.5C4.55964 2.5 4 1.94036 4 1.25Z\" fill=\"#696C80\" data-v-0b65b62f></path><path d=\"M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25Z\" fill=\"#696C80\" data-v-0b65b62f></path></svg></div></div>", 1)),
                                    _createElementVNode("div", _hoisted_48, [
                                      _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.lang(data, "title")), 1),
                                      _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.getItemStatus(data)), 1)
                                    ])
                                  ]))
                                }), 128))
                              ], 2))
                            : _createCommentVNode("", true),
                          _createVNode(_component_draggable, {
                            list: $items,
                            class: _normalizeClass(`items horizontal items-${path}`),
                            group: "values",
                            onStart: _cache[8] || (_cache[8] = ($event: any) => (console.log(`drag = true`))),
                            onEnd: _cache[9] || (_cache[9] = ($event: any) => (console.log(`drag = false`))),
                            onMove: _ctx.moveValue,
                            onChange: _ctx.logValue
                          }, {
                            item: _withCtx(({ element }) => [
                              (_openBlock(), _createElementBlock("div", {
                                class: "item handle",
                                key: element.id
                              }, [
                                _cache[68] || (_cache[68] = _createElementVNode("div", { class: "checkbox flex-mid" }, [
                                  _createElementVNode("div", { class: "checked flex-mid" }, [
                                    _createElementVNode("svg", {
                                      width: "7",
                                      height: "11",
                                      viewBox: "0 0 7 11",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M4 9.25C4 8.55964 4.55964 8 5.25 8C5.94036 8 6.5 8.55964 6.5 9.25C6.5 9.94036 5.94036 10.5 5.25 10.5C4.55964 10.5 4 9.94036 4 9.25Z",
                                        fill: "#696C80"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M0 9.25C0 8.55964 0.559644 8 1.25 8C1.94036 8 2.5 8.55964 2.5 9.25C2.5 9.94036 1.94036 10.5 1.25 10.5C0.559644 10.5 0 9.94036 0 9.25Z",
                                        fill: "#696C80"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M4 5.25C4 4.55964 4.55964 4 5.25 4C5.94036 4 6.5 4.55964 6.5 5.25C6.5 5.94036 5.94036 6.5 5.25 6.5C4.55964 6.5 4 5.94036 4 5.25Z",
                                        fill: "#696C80"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M0 5.25C0 4.55964 0.559644 4 1.25 4C1.94036 4 2.5 4.55964 2.5 5.25C2.5 5.94036 1.94036 6.5 1.25 6.5C0.559644 6.5 0 5.94036 0 5.25Z",
                                        fill: "#696C80"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M4 1.25C4 0.559644 4.55964 0 5.25 0C5.94036 0 6.5 0.559644 6.5 1.25C6.5 1.94036 5.94036 2.5 5.25 2.5C4.55964 2.5 4 1.94036 4 1.25Z",
                                        fill: "#696C80"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25Z",
                                        fill: "#696C80"
                                      })
                                    ])
                                  ])
                                ], -1)),
                                _createElementVNode("div", _hoisted_51, [
                                  _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.lang(element, "title")), 1),
                                  _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.getItemStatus(element)), 1)
                                ])
                              ]))
                            ]),
                            _: 2
                          }, 1032, ["list", "class", "onMove", "onChange"])
                        ], 64))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'defining_your_values')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                      (_ctx.valuesAndTraits && _ctx.valuesAndTraits.scenarios)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                            _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.lang(_ctx.valuesAndTraits.scenarios.definingYourValues, "title")), 1),
                            _createElementVNode("div", {
                              class: "fs-16 mt-12",
                              innerHTML: 
                  _ctx.lang(
                    _ctx.valuesAndTraits.scenarios.definingYourValues,
                    'description'
                  )
                
                            }, null, 8, _hoisted_57),
                            _createElementVNode("div", {
                              class: "fs-16 mt-12 cursor-pointer",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.step = 'defining_values_optional'))
                            }, _toDisplayString("Set values")),
                            _createElementVNode("div", _hoisted_58, [
                              _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("setup.valueAndTraits.Not_at_all_important")), 1),
                              _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.$t("setup.valueAndTraits.Very_important")), 1)
                            ]),
                            _cache[69] || (_cache[69] = _createElementVNode("div", { class: "line" }, null, -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
                  .questions, (question) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: question.key
                              }, [
                                _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.lang(question, "title")), 1),
                                _createElementVNode("div", _hoisted_62, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: option.key,
                                      onClick: ($event: any) => (
                        _ctx.onClickChoice('definingYourValues', question, option)
                      ),
                                      class: _normalizeClass([{
                        select: _ctx.isSelectedChoiceCls(
                          'definingYourValues',
                          question,
                          option
                        ),
                        'btn-rate-1': index == 0,
                        'btn-rate-2': index == 1,
                        'btn-rate-3': index == 2,
                        'btn-rate-4': index == 3,
                        'btn-rate-5': index == 4,
                      }, "btn-rate flex-mid"])
                                    }, _toDisplayString(option.label), 11, _hoisted_63))
                                  }), 128))
                                ])
                              ], 64))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'defining_values_optional')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                      _createElementVNode("div", _hoisted_65, [
                        _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.$t("setup.valueAndTraits.Defining_your_values")), 1),
                        _createElementVNode("div", _hoisted_67, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Select_and_manage_value")), 1),
                          _cache[70] || (_cache[70] = _createElementVNode("div", null, " ", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.You_can_add_values")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_68, [
                          _createVNode(_component_el_transfer, {
                            modelValue: _ctx.valueOptional,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.valueOptional) = $event)),
                            filterable: "",
                            "filter-placeholder": _ctx.$t('placeholder.Search'),
                            data: _ctx.valueDataListOptional,
                            onChange: _ctx.onValuesElTransferChange,
                            titles: [
                    _ctx.$t('setup.valueAndTraits.Values_Library'),
                    _ctx.$t('setup.valueAndTraits.Do_not_include'),
                  ],
                            format: {
                    noChecked: '${total}',
                    hasChecked: '${total}',
                  }
                          }, {
                            "left-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openValueModal(true, true))),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_Value")), 1)
                            ]),
                            "right-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openValueModal(true, false))),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_Value")), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "filter-placeholder", "data", "onChange", "titles"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'identifying_key_traits')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                      _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.lang(_ctx.valuesAndTraits.scenarios.identifyingKeyTraits, "title")), 1),
                        _createElementVNode("div", {
                          class: "fs-16 mt-12",
                          innerHTML: 
                  _ctx.lang(
                    _ctx.valuesAndTraits.scenarios.identifyingKeyTraits,
                    'description'
                  )
                
                        }, null, 8, _hoisted_72),
                        _createElementVNode("div", {
                          class: "fs-16 mt-12 cursor-pointer",
                          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.step = 'idefining_key_traits_optional'))
                        }, _toDisplayString("Set Traits")),
                        _createElementVNode("div", _hoisted_73, [
                          _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.$t("setup.valueAndTraits.Not_at_all_important")), 1),
                          _createElementVNode("div", _hoisted_75, _toDisplayString(_ctx.$t("setup.valueAndTraits.Very_important")), 1)
                        ]),
                        _cache[71] || (_cache[71] = _createElementVNode("div", { class: "line" }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios
                  .identifyingKeyTraits.questions, (question) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: question.key
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(`value-label sort-value-${question.sortValue}`),
                              style: {"margin-top":"1.6rem"}
                            }, _toDisplayString(_ctx.lang(question, "title")), 3),
                            _createElementVNode("div", _hoisted_76, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: option.key,
                                  onClick: ($event: any) => (
                        _ctx.onClickChoice('identifyingKeyTraits', question, option)
                      ),
                                  class: _normalizeClass([{
                        select: _ctx.isSelectedChoiceCls(
                          'identifyingKeyTraits',
                          question,
                          option
                        ),
                        'btn-rate-1': index == 0,
                        'btn-rate-2': index == 1,
                        'btn-rate-3': index == 2,
                        'btn-rate-4': index == 3,
                        'btn-rate-5': index == 4,
                      }, "btn-rate flex-mid"])
                                }, _toDisplayString(option.label), 11, _hoisted_77))
                              }), 128))
                            ])
                          ], 64))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'idefining_key_traits_optional')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.$t("setup.valueAndTraits.Identifying_key_traits")), 1),
                        _createElementVNode("div", _hoisted_81, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Select_and_manage_traits")), 1),
                          _cache[72] || (_cache[72] = _createElementVNode("div", null, " ", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.You_can_add_traits")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_82, [
                          _createVNode(_component_el_transfer, {
                            modelValue: _ctx.traitsOptional,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.traitsOptional) = $event)),
                            filterable: "",
                            "filter-placeholder": _ctx.$t('placeholder.Search'),
                            data: _ctx.traitsDataListOptional,
                            onChange: _ctx.onTraitsElTransferChange,
                            titles: [
                    _ctx.$t('setup.valueAndTraits.Traits_Library'),
                    _ctx.$t('setup.valueAndTraits.Do_not_include'),
                  ],
                            format: {
                    noChecked: '${total}',
                    hasChecked: '${total}',
                  }
                          }, {
                            "left-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[15] || (_cache[15] = ($event: any) => (
                        (_ctx.addTraitsModalStatus = true),
                          (_ctx.addTraitsModalLibrary = true)
                      )),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_traits")), 1)
                            ]),
                            "right-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[16] || (_cache[16] = ($event: any) => (
                        (_ctx.addTraitsModalStatus = true),
                          (_ctx.addTraitsModalLibrary = false)
                      )),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_traits")), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "filter-placeholder", "data", "onChange", "titles"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'setup_complete')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                      _createElementVNode("div", _hoisted_84, [
                        _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.$t("setup.Setup_Complete")), 1),
                        _createElementVNode("div", _hoisted_86, [
                          _createTextVNode(_toDisplayString(_ctx.$t("setup.Thank_you_for_setting")) + "  ", 1),
                          _createElementVNode("span", _hoisted_87, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                          _createTextVNode("  " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_88, _toDisplayString(_ctx.$t("setup.You_can_now_close_this_window")), 1),
                        _createElementVNode("div", {
                          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.closeTab())),
                          class: "btn btn-50 btn-primary mx-auto mt-40",
                          style: {"width":"9rem"}
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              _cache[73] || (_cache[73] = _createElementVNode("div", null, null, -1))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
          _createElementVNode("div", _hoisted_90, [
            _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.$t("setup.valueAndTraits.Setup_Completed")), 1),
            _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.$t("setup.valueAndTraits.Thank_you_for_setting_up")), 1),
            _createElementVNode("div", _hoisted_93, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-primary"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close_Window")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[74] || (_cache[74] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_94, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.addValueModalStatus,
        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.addValueModalStatus) = $event)),
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_95, [
            _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_value")), 1),
            _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_value_to_the")), 1),
            (_ctx.isLangEnEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_98, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Value_English")), 1))
              : _createCommentVNode("", true),
            (_ctx.isLangEnEnabled)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.valueInputEN) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_value_here')
                }, null, 8, _hoisted_99)), [
                  [_vModelText, _ctx.valueInputEN]
                ])
              : _createCommentVNode("", true),
            (_ctx.isLangThEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_100, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Value_Thai")), 1))
              : _createCommentVNode("", true),
            (_ctx.isLangThEnabled)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 3,
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.valueInputTH) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_value_here')
                }, null, 8, _hoisted_101)), [
                  [_vModelText, _ctx.valueInputTH]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_102, [
              _createElementVNode("div", {
                class: "btn btn-50 btn-626262",
                onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.closeValueModal && _ctx.closeValueModal(...args))),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewValueStatusBTN }]),
                onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.submitNewValue())),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.addTraitsModalStatus,
        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.addTraitsModalStatus) = $event)),
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_103, [
            _createElementVNode("div", _hoisted_104, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait")), 1),
            _createElementVNode("div", _hoisted_105, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait_to")), 1),
            (_ctx.isLangEnEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_106, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_English")), 1))
              : _createCommentVNode("", true),
            (_ctx.isLangEnEnabled)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.traitsInputEN) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                }, null, 8, _hoisted_107)), [
                  [_vModelText, _ctx.traitsInputEN]
                ])
              : _createCommentVNode("", true),
            (_ctx.isLangThEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_108, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_Thai")), 1))
              : _createCommentVNode("", true),
            (_ctx.isLangThEnabled)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 3,
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.traitsInputTH) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                }, null, 8, _hoisted_109)), [
                  [_vModelText, _ctx.traitsInputTH]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_110, [
              _createElementVNode("div", {
                class: "btn btn-50 btn-626262",
                onClick: _cache[28] || (_cache[28] = ($event: any) => (
              (_ctx.addTraitsModalStatus = false),
                (_ctx.traitsInputEN = ''),
                (_ctx.traitsInputTH = '')
            )),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewTraitsStatusBTN }]),
                onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.submitNewTraits())),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["sticky-notification", `${_ctx.stickyNotiStatus ? 'show' : 'hide'}`])
    }, [
      _createElementVNode("div", _hoisted_111, " “" + _toDisplayString(_ctx.stickyNotiData.title) + "” " + _toDisplayString(_ctx.$t("create.Selected")), 1),
      _createElementVNode("div", _hoisted_112, [
        _cache[75] || (_cache[75] = _createElementVNode("span", { class: "sign" }, "+", -1)),
        _createElementVNode("label", null, _toDisplayString(_ctx.stickyNotiData.pros), 1)
      ]),
      _createElementVNode("div", _hoisted_113, [
        _cache[76] || (_cache[76] = _createElementVNode("span", { class: "sign" }, "-", -1)),
        _createElementVNode("label", null, _toDisplayString(_ctx.stickyNotiData.cons), 1)
      ]),
      _createElementVNode("div", _hoisted_114, [
        (_openBlock(), _createElementBlock("svg", {
          width: "14",
          height: "14",
          viewBox: "0 0 14 14",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => (_ctx.closeStickyNoti && _ctx.closeStickyNoti(...args)))
        }, _cache[77] || (_cache[77] = [
          _createElementVNode("path", {
            d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
            fill: "white"
          }, null, -1)
        ])))
      ])
    ], 2),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_115, [
          _createElementVNode("div", _hoisted_116, [
            _createElementVNode("div", _hoisted_117, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_118))
                : (_openBlock(), _createElementBlock("img", _hoisted_119)),
              _createTextVNode(" " + _toDisplayString(_ctx.step), 1)
            ]),
            (_ctx.step == 'defining_values_optional')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.beforeSetupDefiningValues())),
                  class: _normalizeClass(["ml-auto btn btn-50 btn-primary", { 'disable-true': _ctx.valuesIncludedOptions.length == 0 }])
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                  (_ctx.isLightColor && _ctx.valuesIncludedOptions.length != 0)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_120))
                    : (_openBlock(), _createElementBlock("img", _hoisted_121))
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.step == 'defining_your_values')
              ? (_openBlock(), _createElementBlock("div", _hoisted_122, [
                  _createElementVNode("div", {
                    onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.yourValueDisableNext())),
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': _ctx.yourValueDisableNextBTN }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor && !_ctx.yourValueDisableNextBTN)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_123))
                      : (_openBlock(), _createElementBlock("img", _hoisted_124))
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            (_ctx.step == 'idefining_key_traits_optional')
              ? (_openBlock(), _createElementBlock("div", _hoisted_125, [
                  _createElementVNode("div", {
                    onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.beforeSetupIdefiningKeyTraits())),
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': _ctx.yourValueDisableNextBTN }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor && !_ctx.yourValueDisableNextBTN)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_126))
                      : (_openBlock(), _createElementBlock("img", _hoisted_127))
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            (_ctx.step == 'identifying_key_traits')
              ? (_openBlock(), _createElementBlock("div", _hoisted_128, [
                  _createElementVNode("div", {
                    onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.step = 'defining_your_values')),
                    class: "btn btn-50 btn-primary"
                  }, [
                    (_ctx.isLightColor)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_129))
                      : (_openBlock(), _createElementBlock("img", _hoisted_130)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.keyTraitsDisableNext())),
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': _ctx.keyTraitsDisableNextBTN }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor && !_ctx.keyTraitsDisableNextBTN)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_131))
                      : (_openBlock(), _createElementBlock("img", _hoisted_132))
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_133))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_134, [
          (_ctx.step == 'get_start')
            ? (_openBlock(), _createElementBlock("div", _hoisted_135, [
                _createElementVNode("div", _hoisted_136, [
                  _createElementVNode("div", _hoisted_137, _toDisplayString(_ctx.$t("setup.valueAndTraits.Setup_your_Values_and_Traits")), 1),
                  _createElementVNode("div", _hoisted_138, _toDisplayString(_ctx.$t("setup.valueAndTraits.Your_company_core_values")), 1),
                  _createElementVNode("div", _hoisted_139, _toDisplayString(_ctx.$t("setup.valueAndTraits.In_the_next_steps_do")), 1)
                ]),
                _createVNode(_component_el_select, {
                  disabled: !_ctx.existsTemplates.length,
                  modelValue: _ctx.selectedExistsTemplate,
                  "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                  class: "ep-select-44",
                  style: {"width":"42rem","margin-top":"6.4rem"},
                  placeholder: _ctx.$t('setup._Use_the_same_setup')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item._id,
                        label: item.form_name,
                        value: item._id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled", "modelValue", "placeholder"]),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[38] || (_cache[38] = 
//@ts-ignore
(...args) => (_ctx.startSetupValues && _ctx.startSetupValues(...args))),
                      class: "mx-auto btn btn-50 btn-primary",
                      style: {"width":"16.6rem","margin-top":"6.4rem"}
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_140))
                        : (_openBlock(), _createElementBlock("img", _hoisted_141))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_142, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[78] || (_cache[78] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ]))
              ]))
            : (_ctx.step == 'defining_values_optional')
              ? (_openBlock(), _createElementBlock("div", _hoisted_143, [
                  _createElementVNode("div", _hoisted_144, [
                    _createElementVNode("div", _hoisted_145, _toDisplayString(_ctx.$t("setup.valueAndTraits.Defining_your_values")), 1),
                    _createElementVNode("div", _hoisted_146, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Select_and_manage_value")), 1),
                      _cache[79] || (_cache[79] = _createElementVNode("div", null, " ", -1)),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.You_can_add_values")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_147, [
                      _createVNode(_component_el_transfer, {
                        modelValue: _ctx.valueOptional,
                        "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.valueOptional) = $event)),
                        filterable: "",
                        "filter-placeholder": _ctx.$t('placeholder.Search'),
                        data: _ctx.valueDataListOptional,
                        onChange: _ctx.onValuesElTransferChange,
                        titles: [
              _ctx.$t('setup.valueAndTraits.Values_Library'),
              _ctx.$t('setup.valueAndTraits.Do_not_include'),
            ],
                        format: {
              noChecked: '${total}',
              hasChecked: '${total}',
            }
                      }, {
                        "left-footer": _withCtx(() => [
                          _createElementVNode("div", {
                            onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.openValueModal(true, true))),
                            class: "btn btn-32 btn-primary",
                            style: {"width":"12rem"}
                          }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_Value")), 1)
                        ]),
                        "right-footer": _withCtx(() => [
                          _createElementVNode("div", {
                            onClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.openValueModal(true, false))),
                            class: "btn btn-32 btn-primary",
                            style: {"width":"12rem"}
                          }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_Value")), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "filter-placeholder", "data", "onChange", "titles"])
                    ])
                  ])
                ]))
              : (_ctx.step == 'defining_your_values')
                ? (_openBlock(), _createElementBlock("div", _hoisted_148, [
                    (_ctx.valuesAndTraits && _ctx.valuesAndTraits.scenarios)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_149, [
                          _createElementVNode("div", _hoisted_150, _toDisplayString(_ctx.lang(_ctx.valuesAndTraits.scenarios.definingYourValues, "title")), 1),
                          _createElementVNode("div", {
                            class: "fs-16 mt-12",
                            innerHTML: 
            _ctx.lang(_ctx.valuesAndTraits.scenarios.definingYourValues, 'description')
          
                          }, null, 8, _hoisted_151),
                          _createElementVNode("div", {
                            class: "fs-16 mt-12 cursor-pointer",
                            onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.step = 'defining_values_optional'))
                          }, _toDisplayString("Set values")),
                          _createElementVNode("div", _hoisted_152, [
                            _createElementVNode("div", _hoisted_153, _toDisplayString(_ctx.$t("setup.valueAndTraits.Not_at_all_important")), 1),
                            _createElementVNode("div", _hoisted_154, _toDisplayString(_ctx.$t("setup.valueAndTraits.Very_important")), 1)
                          ]),
                          _cache[80] || (_cache[80] = _createElementVNode("div", { class: "line" }, null, -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
            .questions, (question) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: question.key
                            }, [
                              _createElementVNode("div", _hoisted_155, _toDisplayString(_ctx.lang(question, "title")), 1),
                              _createElementVNode("div", _hoisted_156, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: option.key,
                                    onClick: ($event: any) => (_ctx.onClickChoice('definingYourValues', question, option)),
                                    class: _normalizeClass([{
                  select: _ctx.isSelectedChoiceCls(
                    'definingYourValues',
                    question,
                    option
                  ),
                  'btn-rate-1': index == 0,
                  'btn-rate-2': index == 1,
                  'btn-rate-3': index == 2,
                  'btn-rate-4': index == 3,
                  'btn-rate-5': index == 4,
                }, "btn-rate flex-mid"])
                                  }, _toDisplayString(option.label), 11, _hoisted_157))
                                }), 128))
                              ])
                            ], 64))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_ctx.step == 'idefining_key_traits_optional')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_158, [
                      _createElementVNode("div", _hoisted_159, [
                        _createElementVNode("div", _hoisted_160, _toDisplayString(_ctx.$t("setup.valueAndTraits.Identifying_key_traits")), 1),
                        _createElementVNode("div", _hoisted_161, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.Select_and_manage_traits")), 1),
                          _cache[81] || (_cache[81] = _createElementVNode("div", null, " ", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("setup.valueAndTraits.You_can_add_traits")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_162, [
                          _createVNode(_component_el_transfer, {
                            modelValue: _ctx.traitsOptional,
                            "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.traitsOptional) = $event)),
                            filterable: "",
                            "filter-placeholder": _ctx.$t('placeholder.Search'),
                            data: _ctx.traitsDataListOptional,
                            onChange: _ctx.onTraitsElTransferChange,
                            titles: [
              _ctx.$t('setup.valueAndTraits.Traits_Library'),
              _ctx.$t('setup.valueAndTraits.Do_not_include'),
            ],
                            format: {
              noChecked: '${total}',
              hasChecked: '${total}',
            }
                          }, {
                            "left-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[43] || (_cache[43] = ($event: any) => (
                  (_ctx.addTraitsModalStatus = true), (_ctx.addTraitsModalLibrary = true)
                )),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_traits")), 1)
                            ]),
                            "right-footer": _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[44] || (_cache[44] = ($event: any) => (
                  (_ctx.addTraitsModalStatus = true), (_ctx.addTraitsModalLibrary = false)
                )),
                                class: "btn btn-32 btn-primary",
                                style: {"width":"12rem"}
                              }, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_traits")), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "filter-placeholder", "data", "onChange", "titles"])
                        ])
                      ])
                    ]))
                  : (_ctx.step == 'identifying_key_traits')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_163, [
                        _createElementVNode("div", _hoisted_164, [
                          _createElementVNode("div", _hoisted_165, _toDisplayString(_ctx.lang(_ctx.valuesAndTraits.scenarios.identifyingKeyTraits, "title")), 1),
                          _createElementVNode("div", {
                            class: "fs-16 mt-12",
                            innerHTML: 
            _ctx.lang(_ctx.valuesAndTraits.scenarios.identifyingKeyTraits, 'description')
          
                          }, null, 8, _hoisted_166),
                          _createElementVNode("div", {
                            class: "fs-16 mt-12 cursor-pointer",
                            onClick: _cache[46] || (_cache[46] = ($event: any) => (_ctx.step = 'idefining_key_traits_optional'))
                          }, _toDisplayString("Set Traits")),
                          _createElementVNode("div", _hoisted_167, [
                            _createElementVNode("div", _hoisted_168, _toDisplayString(_ctx.$t("setup.valueAndTraits.Not_at_all_important")), 1),
                            _createElementVNode("div", _hoisted_169, _toDisplayString(_ctx.$t("setup.valueAndTraits.Very_important")), 1)
                          ]),
                          _cache[82] || (_cache[82] = _createElementVNode("div", { class: "line" }, null, -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.identifyingKeyTraits
            .questions, (question) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: question.key
                            }, [
                              _createElementVNode("div", {
                                class: _normalizeClass(`value-label sort-value-${question.sortValue}`),
                                style: {"margin-top":"1.6rem"}
                              }, _toDisplayString(_ctx.lang(question, "title")), 3),
                              _createElementVNode("div", _hoisted_170, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: option.key,
                                    onClick: ($event: any) => (_ctx.onClickChoice('identifyingKeyTraits', question, option)),
                                    class: _normalizeClass([{
                  select: _ctx.isSelectedChoiceCls(
                    'identifyingKeyTraits',
                    question,
                    option
                  ),
                  'btn-rate-1': index == 0,
                  'btn-rate-2': index == 1,
                  'btn-rate-3': index == 2,
                  'btn-rate-4': index == 3,
                  'btn-rate-5': index == 4,
                }, "btn-rate flex-mid"])
                                  }, _toDisplayString(option.label), 11, _hoisted_171))
                                }), 128))
                              ])
                            ], 64))
                          }), 128))
                        ])
                      ]))
                    : (_ctx.step == 'setup_complete')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_172, [
                          _createElementVNode("div", _hoisted_173, [
                            _createElementVNode("div", _hoisted_174, _toDisplayString(_ctx.$t("setup.Setup_Complete")), 1),
                            _createElementVNode("div", _hoisted_175, [
                              _createTextVNode(_toDisplayString(_ctx.$t("setup.Thank_you_for_setting")) + "  ", 1),
                              _createElementVNode("span", _hoisted_176, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                              _createTextVNode("  " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_177, _toDisplayString(_ctx.$t("setup.You_can_now_close_this_window")), 1),
                            _createElementVNode("div", {
                              onClick: _cache[47] || (_cache[47] = ($event: any) => (_ctx.closeTab())),
                              class: "btn btn-50 btn-primary mx-auto mt-40",
                              style: {"width":"9rem"}
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_178, [
          _createVNode(_component_el_dialog, {
            modelValue: _ctx.addValueModalStatus,
            "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.addValueModalStatus) = $event)),
            width: "560px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_179, [
                _createElementVNode("div", _hoisted_180, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_value")), 1),
                _createElementVNode("div", _hoisted_181, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_value_to_the")), 1),
                (_ctx.isLangEnEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_182, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Value_English")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangEnEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.valueInputEN) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.valueAndTraits.Enter_value_here')
                    }, null, 8, _hoisted_183)), [
                      [_vModelText, _ctx.valueInputEN]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_184, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Value_Thai")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 3,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.valueInputTH) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.valueAndTraits.Enter_value_here')
                    }, null, 8, _hoisted_185)), [
                      [_vModelText, _ctx.valueInputTH]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_186, [
                  _createElementVNode("div", {
                    class: "btn btn-50 btn-626262",
                    onClick: _cache[50] || (_cache[50] = 
//@ts-ignore
(...args) => (_ctx.closeValueModal && _ctx.closeValueModal(...args))),
                    style: {"width":"7rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewValueStatusBTN }]),
                    onClick: _cache[51] || (_cache[51] = ($event: any) => (_ctx.submitNewValue())),
                    style: {"width":"11rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_el_dialog, {
            modelValue: _ctx.addTraitsModalStatus,
            "onUpdate:modelValue": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.addTraitsModalStatus) = $event)),
            width: "560px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_187, [
                _createElementVNode("div", _hoisted_188, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait")), 1),
                _createElementVNode("div", _hoisted_189, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait_to")), 1),
                (_ctx.isLangEnEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_190, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_English")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangEnEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.traitsInputEN) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                    }, null, 8, _hoisted_191)), [
                      [_vModelText, _ctx.traitsInputEN]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_192, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_Thai")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 3,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.traitsInputTH) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                    }, null, 8, _hoisted_193)), [
                      [_vModelText, _ctx.traitsInputTH]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_194, [
                  _createElementVNode("div", {
                    class: "btn btn-50 btn-626262",
                    onClick: _cache[55] || (_cache[55] = ($event: any) => (
              (_ctx.addTraitsModalStatus = false),
                (_ctx.traitsInputEN = ''),
                (_ctx.traitsInputTH = '')
            )),
                    style: {"width":"7rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewTraitsStatusBTN }]),
                    onClick: _cache[56] || (_cache[56] = ($event: any) => (_ctx.submitNewTraits())),
                    style: {"width":"11rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}